<template>
  <div class="px-6 py-2 flex flex-col font-semibold">
    <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
      <button @click="handlePrev">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Account</h2>
    </header>
    <ul>
      <li class="my-6 py-1 flex items-center">
        <OverviewIcon class="h-4" />
        <router-link class="flex-grow text-text-alternate-1 pl-4" to="overview">Overview</router-link>
        <ChevronDownIcon class='h-6 fill-current transform -rotate-90' />
      </li>
      <li class="my-6 py-1 flex items-center">
        <Fundraiser2Icon class="h-5 stroke-2 stroke-current text-text-alternate-4" />
        <router-link class="flex-grow text-text-alternate-1 pl-3.5" to="account-fundraiser">My Fundraisers</router-link>
        <ChevronDownIcon class='h-6 fill-current transform -rotate-90' />
      </li>
      <li class="my-6 py-1 flex items-center">
        <ProfileIcon class="h-5 stroke-current stroke-2 text-text-alternate-4" />
        <router-link
          class="flex-grow text-text-alternate-1 pl-3.5"
          :to="{ name: 'Profile', params: { id: authUser.id } }"
        >
          Profile
        </router-link>
        <ChevronDownIcon class='h-6 fill-current transform -rotate-90' />
      </li>
      <li class="my-6 py-1 flex items-center">
        <Wallet2Icon class="h-5 stroke-current stroke-1 text-text-alternate-4" />
        <router-link class="flex-grow text-text-alternate-1 pl-3.5" to="wallet">Wallet</router-link>
        <ChevronDownIcon class='h-6 fill-current transform -rotate-90' />
      </li>
      <li class="my-6 py-1 flex items-center">
        <PaymentIcon class="h-5 stroke-current stroke-1 text-text-alternate-4" />
        <router-link class="flex-grow text-text-alternate-1 pl-3.5" to="payment">Payment</router-link>
        <ChevronDownIcon class='h-6 fill-current transform -rotate-90' />
      </li>
      <li class="my-6 py-1 flex items-center">
        <MessageIcon class="h-5 fill-current text-text-alternate-4" />
        <router-link class="flex-grow text-text-alternate-1 pl-3.5" to="messages">Messages</router-link>
        <ChevronDownIcon class='h-6 fill-current transform -rotate-90' />
      </li>
      <li class="my-6 py-1 flex items-center">
        <Setting2Icon class="h-5 stroke-current text-text-alternate-4" />
        <router-link class="flex-grow text-text-alternate-1 pl-3.5" to="settings">Settings</router-link>
        <ChevronDownIcon class='h-6 fill-current transform -rotate-90' />
      </li>
    </ul>
    <div class="py-1 mt-8">
      <!-- <span>Log Out</span> -->
      <span class="my-3 py-1 flex items-center">
        <LogOutIcon class="h-5 stroke-current text-text-alternate-4" />
        <router-link class="flex-grow text-text-alternate-1 pl-3.5" to="overview">Log Out</router-link>
      </span>
    </div>
    <!-- </main> -->
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'Accounts',

  setup() {
    const router = useRouter();
    const store = useStore();
    const authUser = store.state.auth.authData;

    const handlePrev = () => {
      router.go(-1);
    };

    return { handlePrev, authUser };
  },
};
</script>
